// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-call-me-index-js": () => import("./../../../src/pages/call-me/index.js" /* webpackChunkName: "component---src-pages-call-me-index-js" */),
  "component---src-pages-contact-us-index-js": () => import("./../../../src/pages/contact-us/index.js" /* webpackChunkName: "component---src-pages-contact-us-index-js" */),
  "component---src-pages-faqs-index-js": () => import("./../../../src/pages/faqs/index.js" /* webpackChunkName: "component---src-pages-faqs-index-js" */),
  "component---src-pages-how-to-claim-index-js": () => import("./../../../src/pages/how-to-claim/index.js" /* webpackChunkName: "component---src-pages-how-to-claim-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-pages-index-js": () => import("./../../../src/pages/pages/index.js" /* webpackChunkName: "component---src-pages-pages-index-js" */),
  "component---src-templates-sanity-page-index-js": () => import("./../../../src/templates/SanityPage/index.js" /* webpackChunkName: "component---src-templates-sanity-page-index-js" */)
}

